
import Vue from "vue";
import { Mentorcast } from "@/types/interfaces";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "DiscoverMentorcastItem",
  props: {
    mentorcast: {
      type: Object as () => Mentorcast
    }
  },
  computed: {
    ...mapGetters({
      platformCurrency: "helper/getDefaultPlatformCurrency"
    }),
    getMentorcastPrice(): string {
      if ((this.mentorcast as any).seat_price > 0) {
        return `${(this as any).platformCurrency} ${
          (this.mentorcast as any).seat_price
        }`;
      } else {
        return "FREE";
      }
    }
  }
});
