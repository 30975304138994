
import Vue from "vue";
import DiscoverMentorcastItem from "@/components/Discover/DiscoverMentorcastItem.vue";

export default Vue.extend({
  name: "DiscoverMentorcastItems",
  components: {
    DiscoverMentorcastItem
  },
  props: {
    mentorcasts: {
      type: Array
    }
  }
});
